* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Serpentine';
}

button {
  background-color: transparent;
  border: none;
  padding: none;
  outline: none;
  cursor: pointer;
}

body {
  background: #f0f0f0;
}

.Configurator {
  position: relative;
  width: 100%;
  height: 100%;
}

.Configurator canvas {
  width: 100%;
  height: 100%;
}

.Configurator .trigger-btn {
  width: 60px;
  height: 50px;
  padding: 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 2;
  cursor: pointer;
  border: 1px solid #adadad;
  box-shadow: 0 2px 10px #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Configurator .trigger-btn span {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  color: #494949;
}

.Configurator .car-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10px;
  column-gap: 20px;
  list-style: none;
  position: fixed;
  bottom: 90px;
  left: 0;
  padding: 10px;
  border: 1px solid #adadad;
  box-shadow: 0 2px 10px #ccc;
  background-color: #fff;
  transform: translateX(-100%);
  transition: transform 300ms ease-in-out;
}

.Configurator .car-list .trigger-btn.car-trigger {
  position: absolute;
  top: calc(100% - 50px);
  left: 100%;
}

.Configurator .car-list .car-item button {
  padding: 0;
  border: none;
  cursor: pointer;
  height: 100%;
}

.Configurator .car-list .car-item button:hover img {
  opacity: 0.5;
}

.Configurator .car-list .car-item img {
  width: 160px;
  height: auto;
  object-fit: contain;
}

.Configurator .car-list .car-item {
  position: relative;
}

.Configurator .car-list .car-item:hover {
  opacity: 0.8;
}

.Configurator .car-list .car-item.selected {
  cursor: none;
  pointer-events: none;
}

.Configurator .car-list .car-item.selected::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1;
}
.Configurator .car-list .car-item.selected::after {
  position: absolute;
  content: "SELECTED";
  font-size: 16px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
}

.Configurator .wheel-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  column-gap: 20px;
  list-style: none;
  position: fixed;
  bottom: 90px;
  right: 0;
  padding: 10px;
  border: 1px solid #adadad;
  box-shadow: 0 2px 10px #ccc;
  background-color: #fff;
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.Configurator .wheel-list .trigger-btn.wheel-trigger {
  position: absolute;
  top: calc(100% - 50px);
  left: -60px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.Configurator .wheel-list .wheel-item button {
  padding: 0;
  border: none;
  cursor: pointer;
  height: 100%;
}

.Configurator .wheel-list .wheel-item button:hover img {
  opacity: 0.5;
}

.Configurator .wheel-list .wheel-item img {
  width: auto;
  height: 90px;
  object-fit: contain;
}

.Configurator .wheel-list .wheel-item {
  position: relative;
}

.Configurator .wheel-list .wheel-item:hover {
  opacity: 0.8;
}

.Configurator .wheel-list .wheel-item.selected {
  cursor: none;
  pointer-events: none;
}

.Configurator .wheel-list .wheel-item.selected::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 1;
}
.Configurator .wheel-list .wheel-item.selected::after {
  position: absolute;
  content: "SELECTED";
  font-size: 16px;
  font-weight: 700;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 2;
}

.Configurator .loading-overlay {
  width: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
}

.Configurator .loading-overlay.screen {
  z-index: 20;
}

.Configurator .loading-overlay.show {
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

.Configurator .loading-overlay.show + #three-canvas {
  opacity: 0.4;
}


.Configurator .loading-overlay img {
  width: 50%;
  height: auto;
  object-fit: contain;
}

@font-face {
  font-family: 'Serpentine';
  src: url("./assets/fonts/SerpentineW01Regular.ttf");
  font-weight: 400;
}

@media (min-width: 800px) {
  .Configurator .car-list {
    flex-direction: row;
    bottom: 40px;
  }

  .Configurator .car-list .trigger-btn.car-trigger {
    top: 50%;
    transform: translateY(-50%);
  }

  .Configurator .car-list .car-item img {
    width: auto;
    height: 90px;
    object-fit: contain;
  }

  .Configurator .wheel-list {
    flex-direction: row;
    bottom: 40px;
  }

  .Configurator .wheel-list .trigger-btn.wheel-trigger {
    top: 50%;
    transform: translateY(-50%);
  }

  .Configurator .loading-overlay img {
    width: 30%;
    max-width: 300px;
  }
}

.App {
  font-family: 'Serpentine';
  height: 100%;
  min-height: 100%;
}
.App input[type="file"] {
  display: none;
}
.App .custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  background-color: #fff;
}
.App .submit-btn {
  margin-top: 2rem;
  border: 1px solid #ccc;
  display: inline-block;
  padding: 13px 23px;
  cursor: pointer;
  background-color: rgb(248, 128, 0);
  color: #fff;
  font-family: 'Serpentine';
  font-size: 1.4rem;
}
.App .submit-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

hr {
  width: 100vw;
}